<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:49:21
 * @LastEditTime: 2023-04-11 17:36:16
 * @Descripttion: 【互动中心】消息
-->
<style lang="scss" scoped>
.message {
  @include innerPage;
  @include pageHead(-42px);

  .page-inner {
    width: 100%;
    height: calc(100% - 198px);
    margin-top: 36px;
    @include flexBox(space-between, flex-start);

    .im {
      &-wrapper {
        flex-grow: 1;
        height: 100%;
        border-radius: 10px;
        background: #edeff6;
        overflow: hidden;
        @include flexBox;
      }

      &-contacts {
        width: 304px;
        height: 100%;
        background: #f0f3fa;
        .notUser{
            text-align: center;
            line-height: 80px;
            color: #999999;
          }
        .contacts {
          &-type {
            width: 274px;
            height: 84px;
            margin: 0 auto;
            box-sizing: border-box;
            border-bottom: 1px solid rgba($color: #8b8b8b, $alpha: 0.24);
            position: relative;
            @include flexBox;

            li {
              width: 50%;
              text-align: center;
              font-size: 16px;
              color: #2a2a2a;
              cursor: pointer;
              line-height: 84px;

              &.current {
                color: #6340c8;
                font-weight: bold;
              }
            }

            &::before {
              content: "";
              width: 1px;
              height: 14px;
              background: rgba($color: #8b8b8b, $alpha: 0.48);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &::after {
              content: "";
              width: 38px;
              height: 5px;
              border-radius: 2.5px;
              background: #58419c;
              position: absolute;
              bottom: 0;
              left: 0;
              transform: translateX(27px);
            }

            &.c-2 {
              &::after {
                transform: translateX(120px);
              }
            }
            &.c-3 {
              &::after {
                transform: translateX(210px);
              }
            }
          }
          &-wrapper {
            width: 294px;
            height: calc(100% - 127px);
            margin: 38px auto 10px;
            overflow: hidden;
            overflow-y: auto;
          }

          &-list {
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;

            li {
              width: 100%;
              height: 68px;
              border-radius: 10px;
              margin-top: 28px;
              padding: 0 30px 0 18px;
              box-sizing: border-box;
              cursor: pointer;
              @include flexBox;

              &:first-child {
                margin-top: 0;
              }

              &.chatting {
                background: rgba($color: #c6c6c5, $alpha: 0.5);
              }

              .el-badge {
                @include flexBox;

                ::v-deep {
                  .el-badge__content {
                    top: 8px;
                    height: 18px;
                    line-height: 18px;
                    background-color: #f66478;
                  }
                }
              }

              .el-avatar {
                border: 1px solid #ececee;
                border-radius: 50%;
              }

              .name {
                font-size: 16px;
                color: #2b2b2b;
                margin: 0 auto 0 10px;
              }

              .status {
                i {
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #2ac293;

                  &.offline {
                    background: #c2c4c8;
                  }
                }

                span {
                  color: #7a7a7a;
                  margin-left: 6px;
                }
              }
            }
          }
        }
      }

      &-chatting {
        width: calc(100% - 304px);
        height: 100%;
        box-sizing: border-box;
        padding: 6px 30px 30px;

        .message {
          &-reply {
            height: calc(100% - 228px);

            h3 {
              font-size: 22px;
              line-height: 78px;
              color: #303030;
            }

            .replay {
              &-box {
                height: calc(100% - 78px);
                border: solid rgba($color: #d7d7d7, $alpha: 0.5);
                border-width: 1px 0;
                padding: 30px 0;
                box-sizing: border-box;
                overflow: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                  width: 0 !important;
                }

                .date {
                  text-align: center;
                  line-height: 24px;
                  margin-bottom: 20px;
                  color: #9d9d9d;
                }
              }

              &-item {
                margin-bottom: 20px;
                @include flexBox;
                align-items: flex-start;

                &.mine {
                  justify-content: flex-end;

                  .replay-text {
                    color: #fff;
                    background: #2ac293;
                    display: flex;
                    flex-wrap: wrap;

                    &::before {
                      content: "";
                      border-right-color: transparent;
                      border-left-color: #2ac293;
                      left: unset;
                      right: -16px;
                      top: 16px;
                    }
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }

              &-text {
                max-width: 60%;
                min-height: 50px;
                background: #fff;
                border-radius: 10px;
                line-height: 26px;
                box-sizing: border-box;
                padding: 12px 20px;
                color: #303030;
                margin: 0 20px;
                position: relative;
                display: flex;
                flex-wrap: wrap;

                .text {
                  text-align: justify;
                }

                .text-img {
                  width: 26px;
                  height: 26px;
                }

                &::before {
                  content: "";
                  border: 10px solid transparent;
                  border-right-color: #fff;
                  position: absolute;
                  left: -16px;
                  top: 16px;
                }
              }
            }
            .selectMore{
              text-align: center;
              line-height: 80px;
              color: #999999;
            }
          }

          &-write {
            width: 100%;
            height: 228px;
            box-sizing: border-box;
            padding-top: 24px;
            overflow: auto;

            .write-tool {
              width: 100%;
              height: 30px;
              @include flexBox;

              button {
                padding: 0;
                border: none;
                outline: none;
                font-size: 30px;
                color: #9d9d9d;
                margin-left: -3px;

                &:last-child {
                  margin-left: 17px;
                }
              }
            }

            .ipt-box {
              height: calc(100% - 100px);
              margin: 14px 0;

              textarea {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                outline: none;
                font-size: 14px;
                color: #303030;
                caret-color: #6340c8;
                resize: none;

                &::-webkit-scrollbar {
                  width: 0 !important;
                }
              }
            }

            .el-button {
              margin-left: calc(100% - 102px);
            }
          }
        }
      }
    }

    .dynamic-wrapper {
      width: 350px;
      height: 100%;
      border-radius: 10px;
      margin-left: 1.82%;

      .wrapper-head {
        height: 72px;
        background: #fff;
        border-radius: 10px 10px 0 0;
        box-sizing: border-box;
        padding: 0 26px;

        h5 {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border-bottom: 1px solid rgba($color: #585858, $alpha: 0.14);
          @include flexBox(space-between);

          span {
            color: #303030;
          }
        }
      }

      .dynamic-list {
        width: 100%;
        box-sizing: border-box;
        padding: 0 26px;
        margin-bottom: 18px;

        &--wrapper {
          max-height: calc(100% - 72px);
          padding: 0 0 18px;
          box-sizing: border-box;
          overflow: auto;
          background: #fff;
          border-radius: 0 0 10px 10px;
        }

        li {
          width: 100%;
          border-bottom: 1px solid rgba($color: #585858, $alpha: 0.14);
          padding-bottom: 28px;

          &:last-child {
            margin-bottom: 0;
            border-bottom: none;
          }

          h5 {
            color: #0b0b0b;
            line-height: 24px;
            padding: 24px 0 10px;
          }

          .dynamic-desc {
            color: #424141;
            line-height: 24px;
            @include ellipsisMultiline(3);
          }

          .time {
            height: 24px;
            color: #949495;
            margin: 8px 0 20px;
            @include flexBox;

            .iconfont {
              font-size: 18px;
              color: #666;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.dialog-container {
  .parents {
    width: calc(100% + 24px);
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;

    li {
      width: calc(100% - 24px);
      height: 122px;
      border-bottom: 1px solid rgba($color: #585858, $alpha: 0.14);
      @include flexBox;

      &:last-child {
        border-bottom: none;
      }

      .info {
        margin-left: 10px;
        font-size: 16px;
        color: #2b2b2b;
        flex-direction: column;
        @include flexBox(center, flex-start);

        .time {
          font-size: 14px;
          height: 24px;
          margin-bottom: -6px;
          color: #949495;
          @include flexBox;

          .iconfont {
            font-size: 18px;
            color: #666;
            margin-right: 6px;
            margin-top: 4px;
          }
        }
      }
    }
  }

  .not-data {
    height: 300px;
    flex-direction: column;
    @include flexBox(center);

    img {
      width: 125px;
    }

    p {
      color: #b6b6b6;
      margin-top: 16px;
      font-size: 14px;
    }
  }

  &--create {
    box-sizing: border-box;
    padding: 46px 0 42px 0;

    .el-form-item:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
  }
}
</style>

<template>
  <section class="message">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
      <div class="head-wrapper">
        <breadcrumb />
        <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
      </div>
    </div>
    <div class="page-inner">
      <!-- im聊天面板 -->
      <div class="im-wrapper">
        <div class="im-contacts">
          <ul class="contacts-type" :class="'c-'+groupTab" v-if="studentGroupId">
            <li :class="groupTab==1?'current':''" @click="setGroup(1)">消息</li>
            <li :class="groupTab==2?'current':''" @click="setGroup(2)">学生</li>
            <li :class="groupTab==3?'current':''" @click="setGroup(3)">家长</li>
          </ul>
          <div class="contacts-wrapper">
            <ul class="contacts-list" v-if="groupTab==1">
              <!-- 正在聊天的消息模块 -->
              <li :class="item.userProfile.userID==userProfile.userID?'chatting':''" @click="setUserID(item.userProfile)" v-for="(item,index) in chatList" :key="index">
              <!-- <li :class="item.userProfile.userID==userProfile.userID?'chatting':''" @click="setUserID(item.userProfile.userID,item.userProfile.nick,item.userProfile.avatar)" v-for="(item,index) in chatList" :key="index"> -->
                <el-badge :value="item.unreadCount" :max="99" :hidden="item.unreadCount<1">
                  <el-avatar fit="cover" :size="62" :src="formatfile(item.userProfile.avatar)" class="avatar">
                    <img src="@assets/images/empty_avatar.png" />
                  </el-avatar>
                </el-badge>
                <p class="name">{{item.userProfile.nick}}</p>
                <p class="status">

                  {{ item.lastMessage.lastTime * 1000 | formatTime('MM-DD') }}
                  <!-- <i></i>
                  <span>在线</span> -->
                </p>
              </li>
              <div class="notUser" v-if="chatList.length<1">~暂无聊天信息~</div>
            </ul>
            <ul class="contacts-list" v-if="groupTab==2">
              <li :class="item.tencent_im_account==userProfile.userID?'chatting':''" @click="setUserID({userID:item.tencent_im_account,nick:item.tencent_im_nick,avatar:item.tencent_im_image})" v-for="(item,index) in studentGroupuser" :key="index"> 
                <el-badge>
                  <el-avatar fit="cover" :size="62" :src="formatfile(item.tencent_im_image)" class="avatar">
                    <img src="@assets/images/empty_avatar.png" />
                  </el-avatar>
                </el-badge>
                <p class="name">{{item.tencent_im_nick}}</p>
              </li>
              <div class="notUser" v-if="studentGroupuser.length<1">~暂无学生~</div>
            </ul>
            <ul class="contacts-list" v-if="groupTab==3">
              <li :class="item.tencent_im_account==userProfile.userID?'chatting':''" @click="setUserID({userID:item.tencent_im_account,nick:item.tencent_im_nick,avatar:item.tencent_im_image})" v-for="(item,index) in parentGroupuser" :key="index"> 
                <el-badge>
                  <el-avatar fit="cover" :size="62" :src="formatfile(item.tencent_im_image)" class="avatar">
                    <img src="@assets/images/empty_avatar.png" />
                  </el-avatar>
                </el-badge>
                <p class="name">{{item.tencent_im_nick}}</p>
              </li>
              <div class="notUser" v-if="studentGroupuser.length<1">~暂无家长~</div>
            </ul>
          </div>
        </div>
        <div class="im-chatting">
          <div class="message-reply">
            <h3 class="bold">{{userProfile.nick || '-'}}</h3>
            <div class="replay-box" ref="replayBox">
              <div ref="replayBoxHeight">
                <div class="selectMore" v-if="!isCompleted">加载更多消息</div>
                <div v-for="(item, index) in messageList" :key="index">
                  <!-- 消息时间 -->
                  <p class="date">{{ item.time * 1000 | caculateTimeago }}</p>
                  <div class="replay-item" :class="item.from != userProfile.userID ? 'mine' : ''">
                    <!-- 对方的头像 -->
                    <el-avatar class="avatar" v-if="item.from == userProfile.userID" fit="cover" :size="52" :src="formatfile(item.avatar)">
                      <img src="@assets/images/empty_avatar.png" />
                    </el-avatar>
                    <!-- 文本 / 文本 + 图片 消息内容 -->
                    <div class="replay-text" v-if="item.type == 'TIMTextElem'">
                      <div v-for="(textitem, textindex) in setDecodeText(item.payload.text)" :key="textindex">
                        <p class="text" v-if="textitem.name === 'text'" v-html="textitem.text" />
                        <img class="text-img" v-else-if="textitem.name === 'img'" :src="textitem.src" />
                      </div>
                    </div>
                    <!-- 图片消息 -->
                    <div class="replay-text" v-if="item.type == 'TIMImageElem'">
                      <el-image style="width: 100px; height: 100px; float: left" :src="item.payload.imageInfoArray[1].imageUrl" fit="cover" :preview-src-list="[formatfile(item.payload.imageInfoArray[0].imageUrl)]"/>
                    </div>
                    <!-- 自己的头像 -->
                    <el-avatar class="avatar" v-if="item.from != userProfile.userID" fit="cover" :size="52" :src="formatfile(userInfo.teuse_image)">
                      <img src="@assets/images/empty_avatar.png" />
                    </el-avatar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- 聊天输入模块 -->
            <message-input @submitInput="submitInput" @upImg="upImg"></message-input>
          </div>
        </div>
      </div>
      <!-- 家长会 -->
      <div class="dynamic-wrapper" v-if="gradeId">
        <div class="wrapper-head">
          <h5>
            <span class="bold">家长会通知</span>
            <el-button type="text" @click="meetingDialog = true">添加 +</el-button>
          </h5>
        </div>
        <div class="dynamic-list--wrapper">
          <ul class="dynamic-list" v-infinite-scroll="loadMeeting" infinite-scroll-disabled="disabled"
            infinite-scroll-distance="1">
            <li v-for="item in parentMeetingData" :key="item.pamee_id">
              <h5 class="bold line-text--1st">{{ item.pamee_title }}</h5>
              <p class="dynamic-desc light">
                {{ item.pamee_remark }}
              </p>
              <p class="time">
                <i class="iconfont">&#xe61a;</i>
                <span>
                  开会时间：
                  {{ item.pamee_time | formatTime("MM月DD日") }} 星期{{
                    item.pamee_time | formatTime("d")
                  }}
                  丨{{ item.pamee_time | formatTime("HH:mm") }}
                </span>
              </p>
              <div class="btns">
                <el-button type="custom_primary" size="small" @click="
                  parentsConfirmed = true;
                showDialog(item.pamee_id);
                ">
                  <b class="pf_bold">{{ item.pamee_surenum || 0 }}</b><span>人确认</span>
                </el-button>
                <el-button type="custom_info" size="small" @click="
                  parentsConfirmed = false;
                showDialog(item.pamee_id);
                ">
                  <b class="pf_bold">{{ item.pamee_nosurenum || 0 }}</b><span>人未确认</span>
                </el-button>
              </div>
            </li>
          </ul>
          <!-- 加载控件 -->
          <div class="custom-loading" v-if="parentMeetingLoading">
            <div class="point-item"></div>
            <div class="point-item"></div>
            <div class="point-item"></div>
          </div>
          <p class="custom-no-more" v-if="noMore">没有更多了~</p>
        </div>
      </div>
    </div>
    <!-- 家长弹窗 -->
    <el-dialog :title="(parentsConfirmed && '已确认家长') || '未确认家长'" :visible.sync="parentsDialog"
      :width="(parentsConfirmed && '530px') || '400px'" @close="hideDialog('parent')">
      <div class="dialog-container">
        <ul class="parents" v-if="!$isEmpty(parentsData)">
          <li class="parent" v-for="item in parentsData" :key="item.pamee_id">
            <el-avatar fit="cover" :size="62" :src="formatfile(item.pauser_image)" class="avatar">
              <img src="@assets/images/empty_avatar.png" />
            </el-avatar>
            <p class="info">
              <span>{{item.stuser_name}}家长【{{ item.pauser_name }}】</span>
              <span class="time" v-if="parentsConfirmed">
                <i class="iconfont">&#xe61a;</i>确认时间：
                {{item.update_time | formatTime('MM月DD日')}} 星期{{item.update_time | formatTime('d')}} 丨{{item.update_time | formatTime('HH:mm')}}
              </span>
            </p>
          </li>
        </ul>
        <div class="not-data" v-if="$isEmpty(parentsData)">
          <img src="@assets/images/no-data2.png" alt />
          <p>暂无家长数据哦~</p>
        </div>
      </div>
    </el-dialog>
    <!-- 添加弹窗 -->
    <el-dialog title="创建家长会" :visible.sync="meetingDialog" width="530px" :show-close="false"
      @close="hideDialog('meeting')">
      <div class="dialog-container--create">
        <el-form ref="meetingForm" :model="meetingForm" label-width="68px" :rules="meetingRules">
          <el-form-item label="标题" prop="pamee_title">
            <el-input v-model.trim="meetingForm.pamee_title" maxlength="100" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="会议地点" prop="pamee_address">
            <el-input v-model.trim="meetingForm.pamee_address" maxlength="100" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="会议时间" prop="pamee_time">
            <el-date-picker placeholder="选择会议时间" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
              v-model="meetingForm.pamee_time" />
          </el-form-item>
          <el-form-item label="会议内容" class="textarea" prop="pamee_remark">
            <el-input v-model.trim="meetingForm.pamee_remark" type="textarea" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="custom_info" size="small" @click="hideDialog('meeting')">取 消</el-button>
        <el-button type="custom_primary" size="small" @click="addMeeting">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  $addParentsMeeting,
  $parentsMeetingList,
  $parents,
} from "@api/interaction";
import { $get_im_groupuser } from "@api/common";
import { formatFile, formatTimeStamp } from "@utils";
import { mapState } from "vuex"
import { decodeText } from "./chat/components/decodeText";
import messageInput from "./chat/components/message-input";
export default {
  name: "interaction_message",
  components: {
    messageInput
  },
  computed: {
    ...mapState('user', ['userInfo']),
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    noMore() {
      return this.parentMeetingData.length >= this.parentMeetingPage.count;
    },
    disabled() {
      return this.parentMeetingLoading || this.noMore;
    },
  },
  data() {
    return {
      gradeId: null,
      classId: null,
      meetingDialog: false, // 家长会创建弹窗
      meetingForm: {}, // 家长会创建表单
      meetingRules: {
        pamee_title: [
          { required: true, message: "请填写会议标题", trigger: "blur" },
        ],
        pamee_address: [
          { required: true, message: "请填写会议地址", trigger: "blur" },
        ],
        pamee_time: [
          { required: true, message: "请选择会议时间", trigger: "change" },
        ],
        pamee_remark: [
          { required: true, message: "请填写会议内容", trigger: "blur" },
        ],
      },
      parentMeetingData: [], // 家长会数据
      parentMeetingLoading: false, // 分页数据加载
      parentMeetingPage: { count: 0, pageIndex: 1 }, // 分页参数
      parentsData: [], // 家长数据
      parentsDialog: false, // 显示家长弹窗
      parentsConfirmed: false, // 家长弹窗类型  true. 已确认  false. 未确认
      //聊天的对象
      userProfile: {
        userID: "",
        avatar:"",
        nick:"",
      },
      //聊天信息列表
      messageList: [],
      //学生列表
      studentGroupuser:[],
      //家长列表
      parentGroupuser:[],
      //当前聊天窗口的高度
      boxHeight:"",
      //分页续拉需要传入的字段
      nextReqMessageID: "",
      // 表示是否已经拉完所有消息。
      isCompleted: true,
      //聊天记录
      chatList: [],
      // //学生群id,
      studentGroupId:"",
      //家长群id
      parentGroupId:"",
      //点击的类型 1聊天 2学生 3家长
      groupTab:1,
      lookchatImg:"",
    };
  },
  created() {
    let { class: classData,tab } = this.$route.params;
    //通过班级点进来
    if(tab==1){
      this.groupTab=tab;
      classData = classData.split(",");
      this.gradeId = classData[0];
      this.classId = classData[1];
      this.studentGroupId = classData[2];
      this.parentGroupId = classData[3];
      /** 获取家长会数据 */
      this.getParentMeeting();
      //获取聊天群组列表
      this.getConversationList()
      //查看学生列表
      this.getStudentGroupuser()
      //查看家长列表
      this.getParentGroupuser()
    }else{
      // 通过消息直接进来
      //获取聊天群组列表
      this.getConversationList()
    }
  },
  mounted() {
    this.$refs.replayBox.addEventListener('scroll', this.replayBoxScroll, true)
  },
  methods: {
    /** 获取家长会数据 */
    async getParentMeeting() {
      let { data: res } = await $parentsMeetingList(
        this.parentMeetingPage.pageIndex
      );
      this.parentMeetingLoading = false; // 数据加载隐藏
      this.parentMeetingPage.count = res.allcount; // 数据总数
      this.parentMeetingData = [...this.parentMeetingData, ...res.data];
    },
    /** 查看学生列表 */
    async getStudentGroupuser() {
      let res = await $get_im_groupuser({
        teacher_im_userid:sessionStorage.getItem("teuserId").toString(),
        teacher_im_groupname:this.studentGroupId
      });
      if(res){
        this.studentGroupuser=res.data
      }
    },
    /** 查看家长列表 */
    async getParentGroupuser() {
      let res = await $get_im_groupuser({
        teacher_im_userid:sessionStorage.getItem("teuserId").toString(),
        teacher_im_groupname:this.parentGroupId
      });
      if(res){
        this.parentGroupuser=res.data
      }
    },
    /** 新增家长会 */
    addMeeting() {
      this.$refs.meetingForm.validate(async (valid) => {
        if (valid) {
          let params = {
            ...this.meetingForm,
            pamee_time: formatTimeStamp(this.meetingForm.pamee_time),
            pamee_grade: this.gradeId,
            sccla_id: this.classId,
          };
          this.hideDialog("meeting");
          let res = await $addParentsMeeting(params);
          if (res) {
            this.$message.success("家长会新建成功");
            this.parentMeetingData = []; // 家长会数据
            this.parentMeetingLoading = false; // 分页数据加载
            this.parentMeetingPage = { count: 0, pageIndex: 1 }; // 分页参数
            this.getParentMeeting();
          }
        }
      });
    },
    /** 加载更多家长会数据 */
    loadMeeting() {
      this.parentMeetingPage.pageIndex = this.parentMeetingPage.pageIndex + 1;
      this.parentMeetingLoading = true;
      setTimeout(() => {
        this.getParentMeeting();
      }, 300);
    },
    /** 展开家长列表弹窗 */
    async showDialog(id) {
      let params = {
        pamee_id: id,
        type: (this.parentsConfirmed && 1) || 2,
      };
      let { data } = await $parents(params);
      this.parentsData = data;
      this.parentsDialog = true;
    },
    /** 关闭家长列表弹窗 */
    hideDialog(type) {
      if (type == "parent") {
        this.parentsData = [];
        this.parentsDialog = false;
      } else {
        this.$refs.meetingForm.resetFields();
        this.meetingForm = {};
        this.meetingDialog = false;
      }
    },
    /** 即时通讯获取当前聊天消息 */
    getMESSAGE_RECEIVED() {
      this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, (event) => {
        if(event.data[0].from==this.userProfile.userID){
          this.messageList.push(event.data[0]);
          //消息设为已读
          // this.massageRead();
          //滚动到最新消息
          this.replayBoxScrollBottom()
        }
      }, this);
    },
    // 取消通讯监听
    deleteMESSAGE_RECEIVED(){
      let onMessageReceived = function(event) {};
      this.tim.off(this.TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
    },
    /** 消息设为已读 */
    massageRead() {
      console.log()
      this.tim.setMessageRead({
        conversationID: "C2C" + this.userProfile.userID,
      }).then((imResponse) => {
        // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
      }).catch(function (imError) {
        // 已读上报失败
        console.warn("已读上报失败-------------------", imError);
      });
    },
    /** 发送消息和表情 */
    submitInput(text) {
      if(text.length<1){
        this.$message({
          message: '请输入聊天内容',
          type: 'warning'
        });
        return false
      }
      let message = this.tim.createTextMessage({
        to: this.userProfile.userID,
        conversationType: "C2C",
        payload: { text },
      });
      this.tim.sendMessage(message);
      this.messageList.push(message);
      setTimeout(() => {
        this.replayBoxScrollBottom();
      }, 20);
      //消息设为已读
      this.massageRead();
    },
    /** 上传图片 */
    upImg({ res }) {
      let message = this.tim.createImageMessage({
        to: this.userProfile.userID,
        conversationType: "C2C",
        payload: {
          file: res,
        },
        needReadReceipt: true,
      });
      this.tim.sendMessage(message);
      this.messageList.push(message);
      setTimeout(() => {
        this.replayBoxScrollBottom();
      }, 20);
    },
    /** 文字图片转译 */
    setDecodeText(text) {
      text = text.replace(/\n/g, '<br/>');
      return decodeText(text);
    },
    /** 获取聊天历史记录 */
    getMessageList(obj, firstLoad = false) {
      this.tim.getMessageList(obj).then((imResponse) => {
        let messageList = imResponse.data.messageList; // 消息列表。
        this.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        this.isCompleted =true
        setTimeout(() => {
         this.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
        }, 1000);
        if (firstLoad) {
          this.messageList = messageList;
          //初始化聊天里面内容的高度
          this.getBoxHeight()
          /** 消息内容滚动到底部 */
          setTimeout(() => {
            this.replayBoxScrollBottom();
          }, 20);
        } else {
          this.messageList = [...messageList, ...this.messageList];
          this.goBoxHeight()
        }
        //消息设为已读
        this.massageRead();
      });
    },
    /** 获取更多历史消息 */
    getMoreMessageList() {
      const obj = {
        conversationID: "C2C" + this.userProfile.userID,
        nextReqMessageID: this.nextReqMessageID,
        count: 15,
      };
      this.getMessageList(obj);
    },
    /** 消息内容滚动到底部 */
    replayBoxScrollBottom() {
      this.$nextTick(() => {
        let scrollH = this.$refs.replayBox.scrollHeight
        this.$refs.replayBox.scrollTop = scrollH
        // this.$refs.replayBox.scrollTo({ top: scrollH, behavior: 'smooth' });
      })
      //消息设为已读
      // this.massageRead();
    },
    //监听到顶部加载更多
    replayBoxScroll() {
      // console.log('距离顶部高度', this.$refs.replayBox.scrollTop)
      if(this.$refs.replayBox.scrollTop<10&&!this.isCompleted){
        this.getMoreMessageList()
      }
    },
    // 获取聊天内容里面的高度
    getBoxHeight(){
      this.boxHeight=this.$refs.replayBoxHeight.offsetHeight
    },
    // 跳转到指定位置高度
    goBoxHeight(){
      this.$nextTick(() => {
        // 获取相差高度
        let scrollTop=parseInt(this.$refs.replayBoxHeight.offsetHeight-this.boxHeight-this.$refs.replayBox.offsetHeight)
        this.$refs.replayBox.scrollTop = scrollTop
        this.boxHeight=this.$refs.replayBoxHeight.offsetHeight
      })
    },
    // 获取聊天消息列表
    getConversationList(){
      //获取会话列表
      this.tim.getConversationList().then(imResponse=>{
        this.chatList = imResponse.data.conversationList
        if(imResponse.data.conversationList.length>0){
          this.userProfile=imResponse.data.conversationList[0].userProfile
          //获取当前聊天历史
          this.getMessageList({ conversationID: "C2C" +  this.userProfile.userID}, true);
          //即时通讯获取当前聊天消息 只需要调用一次
          if(this.messageList.length<1){
           this.getMESSAGE_RECEIVED();
          }
        }


      }).catch(function(imError) {
        console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
      });
      // 会话列表，需要升级后才能使用
      // let groupName=this.studentGroupId
      // if(this.groupTab==2){
      //   groupName=this.parentGroupId
      // }
      //   console.log(groupName,"1-----------------------------------------------------")
      // this.tim.getConversationList({ groupName: groupName }).then(function(imResponse) {
      //   console.log(imResponse,"-----------------------------------------------------")
      //   const conversationList = imResponse.data.conversationList; // 会话列表
      // });
      
      // // 获取全量的会话列表
      // this.tim.getConversationList().then(function(imResponse) {
      //   const conversationList = imResponse.data.conversationList; // 全量的会话列表，用该列表覆盖原有的会话列表
      //   console.log(conversationList,"1-----------------------------------------------------")
      // }).catch(function(imError) {
      //   console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
      // });


    },
    //切换学生还是家长
    setGroup(type){
      this.groupTab=type
      this.getConversationList()
    },
    // 点击聊天对象
    setUserID(userProfile){
      if(this.userProfile.userID==userProfile.userID){
        return false
      }
      // 关闭通讯监听
      this.deleteMESSAGE_RECEIVED()
      this.userProfile=userProfile
      //获取当前聊天历史
      this.getMessageList({ conversationID: "C2C" + userProfile.userID}, true);
      //即时通讯获取当前聊天消息
      // this.getMESSAGE_RECEIVED();
    },
  },
};
</script>